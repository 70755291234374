import { cx } from "class-variance-authority"
import { MouseEvent, useEffect, useRef, useState } from "react"

const HorizontalScrollDiv = ({ children, className }: any) => {
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState<number | null>(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const containerRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    setIsDragging(true)
    setStartX(e.clientX)
    setScrollLeft(containerRef.current?.scrollLeft || 0)
  }

  if (!isClient) return null

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging) return

    const deltaX = e.clientX - startX!
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollLeft - deltaX
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  return (
    <div
      className={cx("overflow-auto", className)}
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {children}
    </div>
  )
}

export default HorizontalScrollDiv
